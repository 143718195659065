.form-check-input:checked {
    background-color: #1bc5bd !important;
    border-color: #1bc5bd !important;
}

.input2:checked {
    background-color: #ffa800 !important;
    border-color: #ffa800 !important;
}


