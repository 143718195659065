.del-button{
    /* display: none; */
    transition: .3s ease-in;
    width: 30px;
    height: 40px;
    /* margin-top: 10px; */
    margin-right: 2px;
    background-color: #dedede;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(100vw, -3px);
}
.card-hover{
    overflow: hidden;
}


.card-hover:hover .del-button{
    transform: translate(0, 0);

}