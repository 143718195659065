/* @font-face {
  font-family: "flaticon";
  src: url("./flaticon.ttf?d63d4800091cd3eedf4d33622a251715") format("truetype"), url("./flaticon.woff?d63d4800091cd3eedf4d33622a251715") format("woff"), url("./flaticon.woff2?d63d4800091cd3eedf4d33622a251715") format("woff2"), url("./flaticon.eot?d63d4800091cd3eedf4d33622a251715#iefix") format("embedded-opentype"), url("./flaticon.svg?d63d4800091cd3eedf4d33622a251715#flaticon") format("svg");
} */
i[class^=flaticon-]:before,
i[class*=" flaticon-"]:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-delivery:before {
  content: "\f101";
}

.flaticon-bicycle:before {
  content: "\f102";
}

.flaticon-bookmark:before {
  content: "\f103";
}

.flaticon-like:before {
  content: "\f104";
}

.flaticon-microphone:before {
  content: "\f105";
}

.flaticon-location:before {
  content: "\f106";
}

.flaticon-gallery:before {
  content: "\f107";
}

.flaticon-share:before {
  content: "\f108";
}

.flaticon-star:before {
  content: "\f109";
}

.flaticon-trash-bin:before {
  content: "\f10a";
}

.flaticon-user-2:before {
  content: "\f10b";
}

.flaticon-view:before {
  content: "\f10c";
}

.flaticon-user:before {
  content: "\f10d";
}

.flaticon-pin:before {
  content: "\f10e";
}

.flaticon-chat:before {
  content: "\f10f";
}

.flaticon-home:before {
  content: "\f110";
}

.flaticon-mail:before {
  content: "\f111";
}

.flaticon-settings:before {
  content: "\f112";
}

.flaticon-alignment-right:before {
  content: "\f113";
}

.flaticon-link:before {
  content: "\f114";
}

.flaticon-attach:before {
  content: "\f115";
}

.flaticon-smile:before {
  content: "\f116";
}

.flaticon-moon:before {
  content: "\f117";
}

.flaticon-sun:before {
  content: "\f118";
}

.flaticon-train:before {
  content: "\f119";
}

.flaticon-eclipse:before {
  content: "\f11a";
}

.flaticon-drone:before {
  content: "\f11b";
}

.flaticon-truck:before {
  content: "\f11c";
}

.flaticon-ship:before {
  content: "\f11d";
}

.flaticon-offline:before {
  content: "\f11e";
}

.flaticon-printer:before {
  content: "\f11f";
}

.flaticon-paperclip:before {
  content: "\f120";
}

.flaticon-attachments:before {
  content: "\f121";
}

.flaticon-attachment:before {
  content: "\f122";
}

.flaticon-dogecoin:before {
  content: "\f123";
}

.flaticon-bitcoin:before {
  content: "\f124";
}

.flaticon-setting:before {
  content: "\f125";
}

.flaticon-headset:before {
  content: "\f126";
}

.flaticon-play:before {
  content: "\f127";
}

.flaticon-pause:before {
  content: "\f128";
}

.flaticon-next:before {
  content: "\f129";
}

.flaticon-back:before {
  content: "\f12a";
}

.flaticon-shuffle:before {
  content: "\f12b";
}

.flaticon-repeat:before {
  content: "\f12c";
}

.flaticon-outgoing-call:before {
  content: "\f12d";
}

.flaticon-incoming-call:before {
  content: "\f12e";
}

.flaticon-cash-payment:before {
  content: "\f12f";
}

.flaticon-mobile-payment:before {
  content: "\f130";
}

.flaticon-card:before {
  content: "\f131";
}

.flaticon-like-1:before {
  content: "\f132";
}

.flaticon-bank:before {
  content: "\f133";
}

.flaticon-telegram:before {
  content: "\f134";
}

.flaticon-drive:before {
  content: "\f135";
}

.flaticon-remote-control:before {
  content: "\f136";
}

.flaticon-house:before {
  content: "\f137";
}

.flaticon-image:before {
  content: "\f138";
}

.flaticon-app-store:before {
  content: "\f139";
}

.flaticon-email:before {
  content: "\f13a";
}

.flaticon-stats:before {
  content: "\f13b";
}

.flaticon-notification:before {
  content: "\f13c";
}

.flaticon-send:before {
  content: "\f13d";
}

.flaticon-insurance:before {
  content: "\f13e";
}

.flaticon-hourglass:before {
  content: "\f13f";
}

.flaticon-calendar:before {
  content: "\f140";
}

.flaticon-alarm:before {
  content: "\f141";
}

.flaticon-layers:before {
  content: "\f142";
}

.flaticon-facebook:before {
  content: "\f143";
}

.flaticon-instagram:before {
  content: "\f144";
}

.flaticon-linkedin:before {
  content: "\f145";
}

.flaticon-globe:before {
  content: "\f146";
}

.flaticon-equalizer:before {
  content: "\f147";
}

.flaticon-settings-1:before {
  content: "\f148";
}

.flaticon-creativity:before {
  content: "\f149";
}

.flaticon-content-marketing:before {
  content: "\f14a";
}

.flaticon-line-chart:before {
  content: "\f14b";
}

.flaticon-cms:before {
  content: "\f14c";
}

.flaticon-hello:before {
  content: "\f14d";
}

.flaticon-password:before {
  content: "\f14e";
}

.flaticon-credit-card:before {
  content: "\f14f";
}

.flaticon-enlarge:before {
  content: "\f150";
}

.flaticon-24-hours:before {
  content: "\f151";
}

.flaticon-heart:before {
  content: "\f152";
}

.flaticon-user-experience:before {
  content: "\f153";
}

.flaticon-web-design:before {
  content: "\f154";
}/*# sourceMappingURL=fonticon.css.map */